import angular from 'angular';
import MODULE from './module';

// templates
import languageStatsTemplate from './admin.language-stats.html';
import mainTemplate from './admin.main.html';
import companiesViewTemplate from './admin.companies.view.html';
import companyManagementCsv from './admin.companies.managementcsv.html';
import senseiEditRepoTemplate from './admin.sensei.edit-repo.html';
import senseiEditCodebaseTemplate from './admin.sensei.edit-codebase.html';
import companyIntegrationsTemplate from './integrations/admin.companies.integrations.view.html';
import teamsViewTemplate from './admin.teams.view.html';
import { FEATURE_EVENTS } from '../../auth/constants';

angular
  .module(MODULE)
  .config([
    '$stateProvider',
    'USER_ROLES',
    function ($stateProvider, USER_ROLES) {
      $stateProvider
        .state('admin.language-stats', {
          url: '/language-stats',
          templateUrl: languageStatsTemplate,
          controller: 'ManagementAdminLanguageStatsController',
          data: {
            authorizedRoles: [USER_ROLES.admin],
          },
        })
        .state('admin.companies', {
          url: '/companies',
          templateUrl: mainTemplate,
          controller: 'ManagementAdminCompaniesController',
          data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller],
          },
        })
        // Note that this redirects to the company's admin feature pop-up
        .state('admin.features', {
          url: '/features',
          onEnter: [
            '$state',
            function ($state) {
              $state.go('admin.company', { redirectToFeatures: true });
            },
          ],
          data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin],
          },
        })
        // Note that this redirects to the logged-in company
        .state('admin.register', {
          url: '/company/integrations/register?request&integrationId',
          onEnter: [
            '$state',
            '$stateParams',
            'FeatureFlagsApi',
            'FeatureFlags',
            'Session',
            function ($state, $stateParams, FeatureFlagsApi, FeatureFlags, Session) {
              FeatureFlagsApi.isFeatureEnabled(FeatureFlags.CUSTOMER_ANALYTICS).then((isEnabled) => {
                if (isEnabled) {
                  const { request: registrationRequest, integrationId } = $stateParams;
                  const company = Session.getUserCompany();
                  $state.go('admin.company.integrations', {
                    companyId: company._id,
                    company,
                    registrationRequest,
                    integrationId,
                  });
                } else {
                  $state.go('unavailable');
                }
              });
            },
          ],
          data: {
            authorizedRoles: [USER_ROLES.companyAdmin],
          },
        })
        .state('admin.company', {
          url: '/company/:companyId',
          templateUrl: companiesViewTemplate,
          controller: 'ManagementAdminCompanyController',
          params: {
            company: null,
            redirectToFeatures: false,
          },
          data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin],
          },
        })
        .state('admin.company.managementcsv', {
          url: '/csv',
          views: {
            '@': {
              templateUrl: companyManagementCsv,
              controller: 'ManagementCsvController',
              resolve: {
                company: [
                  '$stateParams',
                  'Session',
                  function ($stateParams, Session) {
                    return $stateParams.company || Session.getUserCompany();
                  },
                ],
                csvMapping: function () {
                  return ['email', 'role', 'team'];
                },
                defaults: function () {
                  return {};
                },
              },
              params: {
                registrationRequest: null,
                integrationId: null,
                company: null,
              },
            },
          },
          data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin],
          },
        })
        .state('admin.company.integrations', {
          url: '/integrations?registrationRequest&integrationId',
          views: {
            '@': {
              templateUrl: companyIntegrationsTemplate,
              controller: 'ManagementAdminCompanyIntegrationsController',
              params: {
                registrationRequest: null,
                integrationId: null,
              },
            },
          },
          data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.companyAdmin],
          },
        })
        .state('admin.company.features', {
          url: '/features',
          controller: [
            '$uibModal',
            '$state',
            '$scope',
            '$uibModalStack',
            function ($uibModal, $state, $scope, $uibModalStack) {
              $uibModal
                .open({
                  templateUrl: 'NewFeaturesModal.html',
                  controller: [
                    '$rootScope',
                    '$scope',
                    '$window',
                    '$uibModalInstance',
                    'FeatureFlagsApi',
                    function ($rootScope, $scope, $window, $uibModalInstance, FeatureFlagsApi) {
                      $scope.newAppUrl = '/web/index.html';

                      $window.addEventListener('message', receiveMessage);

                      function receiveMessage(event) {
                        if (event.isTrusted && event.origin === location.origin) {
                          var messageType = event.data.type;
                          var messageData = event.data.data;

                          if (messageType === 'closeModal') {
                            $uibModalInstance.close(messageData);

                            if (messageData?.refresh && messageData?.flags) {
                              if (messageData.impersonated) {
                                FeatureFlagsApi.clearAllUserCache();
                              } else {
                                FeatureFlagsApi.clearCacheForFeatures(messageData.flags);
                              }
                              $rootScope.$broadcast(FEATURE_EVENTS.refreshFeatures);
                            }
                          }

                          if (messageType === 'newFeaturesLoaded') {
                            showIframe();
                          }
                        } else {
                          console.warn('iFrame message not sent from trusted origin', event.origin);
                        }
                      }

                      $scope.$on('$destroy', function () {
                        $window.removeEventListener('message', receiveMessage);
                      });

                      function showIframe() {
                        const newFeaturesIframe = document.querySelector('.iframe-container-new-features > iframe');
                        if (newFeaturesIframe) {
                          newFeaturesIframe.style.visibility = 'visible';
                        }
                      }
                    },
                  ],
                  windowClass: 'modal-new-features',
                  size: 'lg',
                  backdrop: 'static',
                })
                .result.finally(function () {
                  $state.go('^');
                });

              $scope.$on('$destroy', function () {
                $uibModalStack.dismissAll();
              });
            },
          ],
        })
        .state('admin.repo', {
          url: '/sensei/repo/:repoID',
          templateUrl: senseiEditRepoTemplate,
          controller: 'ManagementAdminSenseiRepoController',
          data: {
            authorizedRoles: [USER_ROLES.admin],
          },
        })
        .state('admin.codebase', {
          url: '/sensei/codebase/:codebaseID',
          templateUrl: senseiEditCodebaseTemplate,
          controller: 'ManagementAdminSenseiCodebaseController',
          data: {
            authorizedRoles: [USER_ROLES.admin],
          },
        })
        .state('admin.team', {
          url: '/team/:teamId',
          templateUrl: teamsViewTemplate,
          controller: 'ManagementAdminTeamController',
          params: {
            // use for pre-fetch ui population
            team: null,
          },
          data: {
            authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
          },
        });
    },
  ])

  .run([
    '$rootScope',
    function ($rootScope) {
      var styled = [
        'metrics',
        'admin',
        'admin.language.stats',
        'admin.companies',
        'admin.company',
        'admin.company.managementcsv',
        'admin.company.features',
        'admin.register',
        'admin.company.integrations',
        'admin.company.vulnerability-summary-report',
        'admin.repo',
        'admin.codebase',
        'admin.team',
        'admin.user',
        'assessment-metrics',
        'course-metrics',
      ];
      $rootScope.$on('$stateChangeSuccess', function (_event, next, _nextParams, _from, _fromParams) {
        if (_.includes(styled, next.name)) {
          $('#lumen-css').prop('disabled', false);
          $('#slate-css').prop('disabled', true);
        } else {
          $('#lumen-css').prop('disabled', true);
          $('#slate-css').prop('disabled', false);
        }
      });
    },
  ])

  .controller('ManagementAdminController', [
    '$log',
    '$scope',
    '$state',
    '$translate',
    'AuthService',
    'USER_ROLES',
    'AdminApiService',
    'ErrorHandler',
    function ($log, $scope, $state, $translate, AuthService, USER_ROLES, AdminApiService, ErrorHandler) {
      // Begin: exposed methods
      $scope.dateNow = Date.now();
      $scope.goBack = goBack;
      $scope.downloadRatingsCSV = downloadRatingsCSV;
      // End: exposed methods

      function redirect() {
        if ($state.current.name == 'admin') {
          if (AuthService.isAuthorized(USER_ROLES.admin)) {
            $state.go('admin.companies');
          } else if (AuthService.isAuthorized(USER_ROLES.reseller)) {
            $state.go('admin.companies');
          } else if (AuthService.isAuthorized(USER_ROLES.companyAdmin)) {
            $state.go('admin.company', { companyId: null }); // TODO company ID from user.properties
          } else if (AuthService.isAuthorized(USER_ROLES.manager)) {
            $state.go('admin.team', { teamId: null }); // TODO team ID from user.properties
          }
        }
      }

      function init() {
        redirect();
      }

      // utilities
      function goBack(companyId) {
        $log.debug('goBack(%s)', companyId);
        if ($state.current.name == 'admin.team') {
          $state.go('admin.company', { companyId: companyId });
        } else {
          $state.go('admin.companies');
        }
      }

      function downloadRatingsCSV() {
        downloadRatingsCSV.inprogress = true;
        AdminApiService.downloadRatingsCsv()
          .then(function (data) {
            if (window.navigator.msSaveBlob) {
              swal({
                title: $translate.instant('CSV_REPORT_READY'),
                text: $translate.instant(
                  'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON'
                ),
                type: 'success',
                html: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
            } else {
              document.location.href = data.url;
              swal.close();
            }
          })
          .catch(function (response) {
            ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TYPE_RATINGS_CSV'), response);
          })
          .finally(function () {
            downloadRatingsCSV.inprogress = false;
          });
      }

      // lifecycle
      init();
    },
  ]);
