// this is needed to support async/await which gets transpiled to generator functions
import 'regenerator-runtime/runtime';

// global scripts
// jQuery plugins and extensions
import 'jquery-mousewheel';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/progressbar';
import 'jquery.terminal';
/**
 * jVectorMap does not provide a default export, hence we load it via the exports loader which adds
 * `module.exports = jvm` to the bottom of the file. We then use the expose-loader to expose the jvm
 * variable to the global scope.
 */
import 'expose-loader?exposes=jvm!exports-loader?type=commonjs&exports=single|jvm!./vendor/js/jquery-jvectormap-custom.js';
import './vendor/js/jquery-jvectormap-world-mill-en.js';

// SCW scripts
import './scw-angular';
import './vendor/js/integrations/foot.js';

import 'angular-material/angular-material.css';
// main SCW stylesheets
import './css/scw/variables-wanda.css';
import './css/scw/variables.css';
import './css/scw/material.css';
import './style.css';

// theme-overrides
import '@scw/theme-overrides/lib/styles.css';
import '@securecodewarrior/design-system-react/lib/index.css';
