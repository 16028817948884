import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UsersDataGrid } from './organisms/UsersDataGrid';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import InviteUsersModal from './organisms/InviteUsersModal';
import { SingleActionProvider } from '../../common/context';
import { GroupAdd } from '@mui/icons-material';
import { usePlatformContext } from '../../../platformContext';
export const Users = () => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const ctx = usePlatformContext();
    const companyCsvPage = `#/admin/company/${(_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b._cid}/csv`;
    const showManagementCsv = ctx.flagsEnabled.includes('management-csv') && ((_d = (_c = ctx.sessionUser) === null || _c === void 0 ? void 0 : _c.roles) === null || _d === void 0 ? void 0 : _d.includes('company admin'));
    return (_jsxs(Stack, { gap: ({ spacing }) => spacing(10), children: [_jsxs(Stack, { direction: 'row', justifyContent: 'space-between', children: [_jsx(Typography, { variant: "subtitle1", children: t('searchAllUsers') }), _jsxs(Stack, { direction: "row", gap: 2, children: [showManagementCsv && (_jsx(Button, { startIcon: _jsx(GroupAdd, {}), href: companyCsvPage, children: "Management CSV" })), _jsx(InviteUsersModal, {})] })] }), _jsx(Card, { sx: ({ spacing }) => ({ padding: spacing(7) }), children: _jsx(SingleActionProvider, { children: _jsx(UsersDataGrid, {}) }) })] }));
};
