import angular from 'angular';
import MODULE from './module';
import Papa from 'papaparse';

angular.module(MODULE).controller('ManagementCsvController', [
  '$log',
  '$scope',
  '$timeout',
  '$translate',
  '$window',
  'AdminApiService',
  'AuthService',
  'company',
  'csvMapping',
  'defaults',
  'ErrorHandler',
  'ExportUsersCSVFn',
  'Session',
  'USER_ROLES',
  function (
    $log,
    $scope,
    $timeout,
    $translate,
    $window,
    AdminApiService,
    AuthService,
    company,
    csvMapping,
    defaults,
    ErrorHandler,
    ExportUsersCSVFn,
    Session,
    USER_ROLES
  ) {
    $scope.fileExtensionValid = true;
    $scope.acceptUpdate = acceptUpdate;
    $scope.isNothingToDo = isNothingToDo;
    $scope.isReadyToProceed = isReadyToProceed;
    $scope.simulateImpact = simulateImpact;
    $scope.exportUserListsCSV = exportUserListsCSV;
    $scope.resetDetailViewTab = resetDetailViewTab;
    $scope.filter = dashFilter;
    $scope.process = process;
    $scope.removeWarning = removeWarning;
    $scope.errored = [];
    $scope.users = [];
    $scope.goBackToUserManagement = function () {
      window.location.href = '#/user-management';
    };
    $scope.ui = {
      stage: 'upload',
      simulation: {
        isNewFilter: { isNew: true },
        isUpdateFilter: { isUpdate: true },
        isIgnoreFilter: { isIgnored: true },
      },
      // modal: $uibModalInstance,
      detailView: {
        sort: 'email',
        // filter: detailViewFilter,
      },
    };
    $scope.simulationSummary = {
      newUsersCount: '...',
      usersInDisabledTeamCount: '...',
    };

    const operationPayload = ($scope.operationPayload = {
      company: company?._id,
      simulate: false,
      update: false,
    });

    $scope.$watch('fileupload.csvFile', processFile);

    $scope.$watch('ui.stage', function (newValue, oldValue) {
      if (newValue === 'detailView') {
        $scope.simulationSummary.users = $scope.simulationSummary.users.filter((u) => !u.teamIsDisabled);
        $scope.discardedWarnings = $scope.warnings.filter(
          (entry) => !operationPayload.update && entry.error === 'CANT_UPDATE_COMPANY_ADMIN'
        );
        $scope.warnings = _.difference($scope.warnings, $scope.discardedWarnings);
      }

      if (newValue === 'summary' && oldValue === 'detailView') {
        $scope.warnings = _.uniqBy(
          [...$scope.warnings, $scope.discardedWarnings].filter((u) => !!u),
          'email'
        );
      }
    });

    function isFileExtensionCorrect(file) {
      return file.name.split('.').pop().toLowerCase() === 'csv';
    }

    function exportUserListsCSV(event) {
      if (event) {
        event.stopPropagation();
      }

      const query = {
        'properties._cid': company._id,
        roles: [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player],
      };

      return ExportUsersCSVFn(query);
    }

    function processFile(file) {
      file = file || $scope?.fileupload?.csvFile?.name;

      if (!file) {
        return;
      }

      if (!isFileExtensionCorrect(file)) {
        $scope.fileExtensionValid = false;
        return;
      }

      $scope.fileExtensionValid = true;

      const reader = new $window.FileReader();
      reader.onloadend = loadHandler;
      reader.readAsText(file);

      function processEntry(entry, csvMapping) {
        const TAGS_INDEX = 3;
        const user = { ...defaults };
        let tags = [];

        if (Array.isArray(entry)) {
          csvMapping.forEach((attr, idx) => {
            if (entry[idx]) {
              user[attr] = entry[idx].trim();
            }
          });

          if (entry[TAGS_INDEX]) {
            tags = entry[TAGS_INDEX].split(';')
              .map((tag) => tag.trim())
              .filter((tag) => tag !== '');
          }
          user.properties = { tags: tags };

          return user;
        }

        return {
          ...entry,
        };
      }

      function loadHandler(evt) {
        const content = evt.target.result;
        const users = [];
        Papa.parse(content, {
          comments: '#',
          step: (results, _parser) => {
            const result = results.data[0].trim().search(/^email$/i);
            if (result === -1) {
              const user = processEntry(results.data, csvMapping);

              if (user.email === Session.user.email) {
                return;
              }

              if (!user.email || user.email.trim() === '') {
                return;
              }

              users.push(user);
            }
          },
        });

        $scope.users = users;
        simulateImpact();
      }
    }

    function isFixable(entry) {
      return ['INVALID_TEAM', 'INVALID_ROLE', 'INVALID_EMAIL'].indexOf(entry.error) > -1;
    }

    function isNotFixable(entry) {
      return !isFixable(entry);
    }

    function acceptUpdate(accept) {
      if (accept) {
        if (!acceptUpdate.confirm) {
          acceptUpdate.confirm = true;

          return $timeout(function () {
            acceptUpdate.confirm = false;
          }, 4000);
        }

        acceptUpdate.confirm = false;
        operationPayload.update = true;
      } else {
        $scope.users.forEach((value) => {
          value.isIgnored = !value.isNew || value.isIgnored;
        });
        operationPayload.update = false;
        acceptUpdate.ignore = true;
      }
    }

    function isReadyToProceed() {
      if (!$scope.simulationSummary) {
        return false;
      }

      if ($scope.simulationSummary.updatedUsersCount > 0) {
        if (!operationPayload.update && !acceptUpdate.ignore) {
          return false;
        }
      }

      return true;
    }

    function isNothingToDo() {
      if (operationPayload.update && $scope.simulationSummary.updatedUsersCount > 0) {
        return false;
      }

      return $scope.simulationSummary.newUsersCount <= 0;
    }

    function resetDetailViewTab() {
      $scope.ui.detailView.search = '';
      $scope.ui.detailView.page = 1;
    }

    function dashFilter(source, filter) {
      const signature = JSON.stringify(filter);

      if (!source.cache) {
        source.cache = {};
      }

      if (!source.cache[signature]) {
        source.cache[signature] = _.filter(source, filter);
      }
      return source.cache[signature];
    }

    function simulateImpact() {
      if ($scope.ui.simulating) {
        return;
      }

      $scope.ui.stage = 'upload';
      $scope.ui.simulating = true;
      $scope.users = _.uniqBy(
        []
          .concat($scope.users, $scope.warnings)
          .filter((u) => !!u)
          .map((u) => ({
            ...u,
            email: u.email.toLowerCase(),
          })),
        'email'
      );

      const operationPayload = {
        company: company._id,
        simulate: true,
        update: true,
      };

      return AdminApiService.bulkManageUsers($scope.users, operationPayload)
        .then(function (summary) {
          summary.users.forEach((user) => {
            $log.debug('User has team(s)', user.team);
          });

          // non-fixable errors
          $scope.warnings = _.uniqBy(
            summary.users.filter((u) => u.errored && isNotFixable(u)),
            'email'
          );
          $scope.errored = _.uniqBy(
            summary.users.filter((u) => u.errored && isFixable(u)),
            'email'
          );
          $scope.users = _.differenceBy(summary.users, $scope.errored, 'email');
          $scope.users = _.differenceBy($scope.users, $scope.warnings, 'email');

          summary.updatedUsersCount = 0;
          summary.newUsersCount = 0;
          summary.reassignedUsersCount = 0;
          summary.usersInDisabledTeamCount = 0;

          $scope.users = $scope.users.map((u) => {
            u.teamIsDisabled = !!u.teamIsDisabled;
            u.isIgnored = u.teamIsDisabled;
            u.isNew = !u._id && !u.isIgnored;
            u.isUpdate = !u.isNew && !u.isIgnored;

            if (u.properties) {
              u.previousRole = u.properties?._previousRole || AuthService.mainRole(u.roles);
              u.isReassigned = u.properties?._previousTeam !== u.properties._tid;
              u.isRoleShift = u.previousRole !== u.role;
            }

            if (!u.teamIsDisabled) {
              summary.updatedUsersCount += u.isUpdate ? 1 : 0;
              summary.newUsersCount += u.isNew ? 1 : 0;
              summary.reassignedUsersCount += u.isReassigned ? 1 : 0;
            } else {
              summary.usersInDisabledTeamCount++;
            }

            return u;
          });

          $scope.simulationSummary = summary;
          $scope.ui.stage = 'summary';
          $scope.teamSearch.results = [...new Set([...summary.users.map((u) => u.team)])].map((t) => ({ name: t }));

          return summary;
        })
        .catch(function (err) {
          ErrorHandler.addHttpError($translate.instant('UNABLE_TO_APPLY_CHANGES'), err);
        })
        .finally(function () {
          $timeout(function () {
            $scope.ui.stage = 'summary';
            $scope.ui.simulating = false;
          }, 1000);
        });
    }

    function process() {
      $scope.ui.processing = true;
      return AdminApiService.bulkManageUsers($scope.users, $scope.operationPayload)
        .then(function (_summary) {
          swal({
            type: 'success',
            title: $translate.instant('Admin.BulkInviteUsers.Modal.Messages.Success.title'),
            text: $translate.instant('Admin.BulkInviteUsers.Modal.Messages.Success.text'),
            confirmButtonText: $translate.instant('OK'),
          });
          // $uibModalInstance.close(true);
        })
        .catch(function (err) {
          ErrorHandler.addHttpError($translate.instant('UNABLE_TO_APPLY_CHANGES'), err);
        })
        .finally(function () {
          $scope.ui.processing = false;
        });
    }

    function removeWarning(entry) {
      if (entry.error === 'CANT_UPDATE_COMPANY_ADMIN') {
        entry.role = USER_ROLES.companyAdmin;
        delete entry.error;
        delete entry.errored;

        $scope.users.push(entry);
        return simulateImpact();
      }

      _.remove($scope.warnings, entry);
    }
  },
]);
